import React from "react"
import Layout from "../components/layout"
import {graphql} from 'gatsby'
import {Link} from "gatsby"
import {Helmet} from "react-helmet"

export const query = graphql`
  query IndexPageQuery {

    allJobs(
       sort: {order: ASC, fields: pickupOrder},
       filter: {isPickup: {eq: true}},
       limit: 4
     ) {
       edges {
         node {
           id
           url
           department
           title
           title_en
           description
           logo
         }
       }
    }
  }
`

const IndexPage = ({data}) => {

  const jobNodes = data.allJobs.edges;

  return (
 
    <Layout mainId="pageHome">
      <Helmet>
        <title>LUCHE GROUP</title>
        <meta name="description" content="LUCHE GROUPが目指すのは、ギフトを通じて幸せや笑顔が増える世界です。 その実現のために、ギフトにフォーカスしたマーケットプレイスやメディアプラットフォームの開発に取り組んでいます。" />
        <link rel="canonical" href="https://luchegroup.com" />
      </Helmet>
      <div id="keyv">
        <div className="key__image"></div>
        <div className="inner">
          <div className="key__text">
              <h2>The Best <br />
                  Feelings, <br />
                  The <br className="spDisplay" />Perfect <br className="pcDisplay" />
                  Gift</h2>
              <p className="txt--keyv bg--keyv">国や世代、常識もこえて <br />
                  いちばん喜ばれるプレゼント選びを。<br />
                  テクノロジーの力で<br className="spDisplay "/>「ありがとう」の気持ちと<br className="pcDisplay "/>「笑顔」が<br className="spDisplay "/>溢れる社会の実現を目指します。</p>
              <Link to="/about_us" className="key__button hover">Who We Are <img src="/images/common/icon_arrow.svg" alt="" /></Link>
          </div>
        </div>
      </div>
      {/* / #keyv  */}
      <div id="contents">
          <section className="sect--services" id="services">
              <div className="inner">
                  <h2 className="headline--1">Services</h2>
                  <p className="txt--common">
                    LUCHE GROUPが目指すのは、ギフトを通じて幸せや笑顔が増える世界です。<br />
                    その実現のために、ギフトにフォーカスしたマーケットプレイスやメディアプラットフォームの開発に取り組んでいます。
                  </p>
              </div>
              <ul className="patner">
                 <li className="patner__item">
                     <img src="/images/home/logo-bpg.svg" alt="Best Persent Guide" />
                 </li>
                 <li className="patner__item">
                     <img src="/images/home/logo-bpr.svg" alt="Best Persent Ranking" />
                 </li>
                 <li className="patner__item">
                     <img src="/images/home/logo-ow.svg" alt="Anny" />
                 </li>
                 <li className="patner__item">
                     <img src="/images/home/logo-gz.svg" alt="Giftization" />
                 </li>
                 <li className="patner__item">
                      <img src="/images/home/logo-an.svg" alt="Anny" />
                  </li>
                  <li className="patner__item">
                      <img src="/images/home/logo-gm.svg" alt="Gitfmall" />
                  </li>
                  <li className="patner__item">
                      <img src="/images/home/logo-mt.svg" alt="Magical Trip" />
                  </li>
              </ul>
              <div className="scrollbar">
                  <div className="scrollbar__image">
                      <figure>
                          <img src="/images/home/img-gm_01.jpg" alt="Gitfmall" />
                      </figure>

                      <figure>
                          <img src="/images/home/img-an_01.jpg" alt="Anny" />
                      </figure>
                      
                      <figure>
                          <img src="/images/home/img-bp_01.jpg" alt="Best Persent Ranking" />
                      </figure>

                      <figure>
                          <img src="/images/home/img-bpg_01.jpg" alt="Best Persent Guide" />
                      </figure>
                      
                      <figure>
                          <img src="/images/home/img-gz_01.jpg" alt="Giftization" />
                      </figure>
                  </div>
              </div>
              <div className="inner">
                  <p className="txt--center"><Link to="/services" className="key__button hover">Our Services <img src="/images/common/icon_arrow.svg" alt="" /></Link></p>
              </div>
         </section>

         <div className="sect--join-us">
              <div className="inner">
                  <h2 className="headline--1">Join Us!</h2>
                  <p className="txt--common txt-center">私たちと一緒に、新しいワクワクに向かって挑戦してみませんか？</p>
                  <div className="block--scroll">
                      <div className="content--join">

                          {
                         	  jobNodes.map((jobNode) => { 
                              const job = jobNode.node
                              return (
                                <a key={job.id} href={job.url} rel="noreferrer" target="_blank" className="content--join-item">
                                    <div className="content--join-hdg">
                                        <p className="content--hdg-sub">{job.department}</p>
                                        <h4 className="content--hdg-title">{job.title}<span>{job.title_en}</span></h4>
                                        <p className="content--hdg-text">{job.description}</p>
                                    </div>
                                    <div className="content--join-logo">
                                        <p><img src={job.logo} alt={job.title_en} /><span className="content--join-icon"><img src="/images/common/icon_arrow.svg" alt="" /></span></p>
                                    </div>
                                </a>
                              );

                            })
                          }

                      </div>
                  </div>
                  <p className="txt--center"><Link to="/careers" className="key__button sect--btn hover">Careers<img src="/images/common/icon_arrow.svg" alt="" /></Link></p>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage
